const SELECTORS = {
  READ_MORE_CTA: 'js-read-more-cta',
}

// Function to add the Read more link if text is more than allowed characters
function addReadMoreFunctionality() {
  const mainContentContainer: HTMLElement | null = document.getElementById(
    'overview-content-details'
  )
  const htmlContent: string | undefined = mainContentContainer?.innerHTML.trim()
  const allowedMaxLength: number = mainContentContainer
    ? Number(mainContentContainer.getAttribute('content-max-length') || 500)
    : 500
  const contentReadMoreText: string = mainContentContainer
    ? mainContentContainer.getAttribute('content-read-more-text') || 'Read more'
    : 'Read more'

  if (htmlContent && countCharactersWithoutPTags(htmlContent) > allowedMaxLength) {
    const trimmedContent: string = trimHtmlString(
      htmlContent,
      allowedMaxLength + 1,
      contentReadMoreText
    )
    if (mainContentContainer) mainContentContainer.innerHTML = trimmedContent

    const readMoreLink: HTMLAnchorElement | null = mainContentContainer?.querySelector(
      `.${SELECTORS.READ_MORE_CTA}`
    ) as HTMLAnchorElement | null
    if (readMoreLink) {
      readMoreLink.addEventListener('click', () => {
        if (mainContentContainer) {
          mainContentContainer.innerHTML = htmlContent
          readMoreLink.remove()
        }
      })
    }
  }
}

// Function to count characters without <p> tags
function countCharactersWithoutPTags(htmlContent: string): number {
  // Remove <p> tags and their content from the HTML
  const contentWithoutPTags: string = htmlContent.replace(/<\/?p[^>]*>/g, '')

  // Count the characters in the modified HTML
  const characterCount: number = contentWithoutPTags.length

  return characterCount
}

// Function to trim the HTML string after a certain number of characters while preserving tags
function trimHtmlString(
  htmlContent: string,
  maxLength: number,
  contentReadMoreText: string
): string {
  let charCount = 0
  let result = ''

  for (let i = 0; i < htmlContent.length; i++) {
    const char: string = htmlContent[i]
    if (char === '<') {
      const endIndex: number = htmlContent.indexOf('>', i)
      if (endIndex !== -1) {
        result += htmlContent.substring(i, endIndex + 1)
        i = endIndex
      }
    } else {
      result += char
      charCount++
    }

    if (charCount === maxLength) {
      result += ` ... <button type="button" class="${SELECTORS.READ_MORE_CTA} read-more-cta">${contentReadMoreText}</button>`
      break
    }
  }

  return result
}

addReadMoreFunctionality()
