function updateVisibilityBasedOnFavorites() {
  const hasFavorites = window.localStorage.getItem('favourites')

  const isFavouritesCollection = document.querySelector('.is-favourites-collection')
  const emptyFavouritesMessage = document.querySelector('.empty-favourites-list-message')
  const isLocalHost = window.location.hostname === 'localhost'
  if (hasFavorites || isLocalHost) {
    if (isFavouritesCollection) {
      isFavouritesCollection.classList.add('is-visible')
    }
    if (emptyFavouritesMessage) {
      emptyFavouritesMessage.classList.remove('is-visible')
    }
  } else {
    if (isFavouritesCollection) {
      isFavouritesCollection.classList.remove('is-visible')
    }
    if (emptyFavouritesMessage) {
      emptyFavouritesMessage.classList.add('is-visible')
    }
  }
}
setTimeout(() => {
  updateVisibilityBasedOnFavorites()
}, 2000)

// Ensure jQuery is loaded and body has the ID 'body-discovery-map' before executing jQuery code
window.jQuery?.(function () {
  if (jQuery('#body-discovery-map').length > 0) {
    // Select the parent of .empty-favourites-list-message and apply styles
    jQuery('.empty-favourites-list-message').parent('.cm').css('margin', '0')
  }
})
