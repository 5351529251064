export function lazyLoad(
  target: HTMLElement,
  loader: () => void | Promise<void>,
  options: IntersectionObserverInit = {
    rootMargin: '0px 0px 150px 0px',
    threshold: 0,
  }
) {
  const observer = new IntersectionObserver(function ([entry], self) {
    if (entry.isIntersecting || entry.intersectionRatio > 0) {
      void loader()
      self.unobserve(target)
    }
  }, options)

  observer.observe(target)
}
