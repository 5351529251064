import { compressToUTF16 } from 'lz-string'

export function toBoolean(value: string | undefined): boolean {
  return !!value && ['true', '1'].includes(value)
}

export function subtractArrays(
  originalArray: string[] | undefined,
  selectedArray: string[] | undefined
): string[] {
  if (!originalArray) return []
  if (!selectedArray) return originalArray
  if (originalArray.length >= selectedArray.length) {
    return originalArray.filter((item) => !selectedArray.includes(item))
  } else {
    return selectedArray.filter((item) => !originalArray.includes(item))
  }
}

export function prepareUrlString(value: string) {
  return encodeURIComponent(compressToUTF16(value))
}
