const bots = [
  'crawl',
  'Index',
  'Validator',
  'MegaIndex',
  'ltx71',
  'apache',
  'facebookexternalhit',
  'facebookplatform',
  'Go-http-client',
  'Probe',
  'AppEngine',
  'bot',
  'spider',
  'curl',
  'slurp',
  'Java',
  'Search',
  'WebDAV',
  'monitor',
  'check',
  'plugin',
  'cortex',
  'EPiServer',
  'cache',
  'wordpress',
  'Wget',
  'test',
  'SEO',
  'Python',
  'Link',
  'Sleuth',
  'Facebook',
  'archive',
  'Xenu',
  'Yahoo',
] as const

const botUserAgentRegex = new RegExp(bots.join('|'), 'i')

export function isBot(userAgent = window.navigator.userAgent): boolean {
  return typeof window !== undefined && botUserAgentRegex.test(userAgent)
}
